@import "../var";
@import "var_pro";

@import "../base";

hr{
    height: 80px;
    width: 100%;
    max-width: $site-width;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border: none;
    background: url('../../images/separator.svg') no-repeat center;
    background-size: calc(100% - ($site-width-pad-x * 2));

    @media (min-width: ($s-mobile)) {
        margin-top: 56px;
        margin-bottom: 26px;
    }
}


header .nav-content .wrapper .header-menu-secondary .button {


    &.button2{
        // Ugly hack because the button is not the same color on mobile and desktop

        @media(min-width: $s-nav-burger-limit) {
            box-shadow: none;
            background: $btn-bg-color-secondary;
            color: $btn-text-color-secondary;

            .no-touchevents &:hover {
                background-color: $btn-bg-color-secondary;
                color: $btn-text-color-secondary;
                box-shadow: 0px 0px 0px 0 transparent, // Bottom right external shadow
                inset 4px 4px 6px 0 rgba(#000, 0.1), // Top left inner shadow
                inset -2px -2px 6px 0 rgba(#fff, 0.5); // Bottom right inner highlight
            }
        }
    }
}


#prefooter{
    .prefooter-content{
        padding: 40px 0px;

        @media (min-width: ($s-mobile)) {
            padding: 40px 40px;
        }

        @media (min-width: ($s-tablet)) {
            padding: 70px 60px;
        }
    }

    .title{
        margin-bottom: 0;
    }

    .text{
        margin-top: 16px;
    }

    .link{
        margin-top: 28px;
    }
}


section {

    &.banner {

        .banner-content {
            padding-top: 27px;
            padding-bottom: 27px;

            @media (min-width: $s-mobile) {
                padding-top: 56px;
                padding-bottom: 56px;
            }

            //padding-top: $header-height-min + $section-pad-y-min;
            //
            //@media (min-width: $s-nav-burger-limit) {
            //    padding-top: $header-height-max + $section-pad-y-max;
            //}
            //
            //main.with-top-banner & {
            //    padding-top: $header-height-min + $header-top-banner-height + $section-pad-y-min;
            //
            //    @media (min-width: $s-nav-burger-limit) {
            //        padding-top: $header-height-max + $header-top-banner-height + $section-pad-y-max;
            //    }
            //}

            .title{
                margin-bottom: 10px;

                @media (min-width: $s-mobile) {
                    margin-bottom: 20px;
                }
            }

            .text {
                font-size: 14px;

                @media (min-width: $s-mobile) {
                    font-size: 18px;
                }
            }
        }
    }
}


// Specific for page home
body.page.home {

    section.banner{

        .banner-content{
            padding-top: 30px;
            padding-bottom: 0px;
            text-align: center;

            @media (min-width: ($s-mobile)) {
                padding-top: 82px;
                margin-bottom: 0px;
            }

            .site-content{
                width: 100%;
            }
        }

        .infos{
            max-width: 700px;
            margin: 0 auto;

            .title{
                margin-bottom: 20px;
            }

            .text{
                margin-bottom: 20px;

                @media (min-width: ($s-mobile)) {
                    margin-bottom: 40px;
                }
            }
        }

        .pictures{
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            overflow: hidden;
            margin-top: 10px;

            @media (min-width: ($s-mobile)) {
                margin-top: -50px;
            }

            @media (min-width: ($s-tablet)) {
                margin-top: -85px;
            }
        }

        .picture-left,
        .picture-right{
            img{
                width: 100%;
                max-height: 270px;

                @media (min-width: ($s-tablet)) {
                    height: 270px;
                    max-width: 420px;
                    width: auto;
                }
            }
        }

        .scroll-indicator{
            @include scroll-indicator(137px, 0.365);
            //$size: 137px;
            //$offset: 0.365;
            //
            //position: relative;
            //bottom: - $size * $offset;
            //
            //.scroll-down {
            //    position: absolute;
            //    bottom: 20px;
            //    width: 100%;
            //    height: 100% - (100% * $offset);
            //    inset: 0;
            //
            //    a{
            //        display: flex;
            //        align-items: flex-end;
            //        justify-content: center;
            //        height: 100%;
            //        width: 100%;
            //        text-decoration: none;
            //
            //        > i {
            //            display: flex;
            //            align-items: center;
            //            justify-content: center;
            //            font-size: 12px;
            //        }
            //    }
            //
            //    &.animated {
            //        a > i {
            //            animation: 2s ease-out infinite fade_move_down;
            //        }
            //
            //        @keyframes fade_move_down {
            //            0% {
            //                transform: translate(0, -250%);
            //                opacity: 0;
            //            }
            //            50% {
            //                opacity: 1;
            //            }
            //            100% {
            //                transform: translate(0, -50%);
            //                opacity: 0;
            //            }
            //        }
            //    }
            //}
            //
            //.picture-circle{
            //    display: none;
            //    position: relative;
            //    justify-content: center;
            //    align-items: center;
            //    width: $size;
            //    height: $size;
            //    animation: rotation 30s infinite linear;
            //
            //    img{
            //        width: 100%;
            //    }
            //
            //    @media (min-width: ($s-mobile-md)) {
            //        display: flex;
            //    }
            //}
        }

    }

    section.customers{
        text-align: center;
        padding-top: 30px;
        padding-bottom: 15px;

        @media (min-width: ($s-mobile)) {
            padding-top: 44px;
            padding-bottom: inherit;
        }

        .customers-content{

        }

        .title{
            margin-bottom: 28px;
        }

        .logos-container{
            position: relative;
            display: flex;
            overflow: hidden;

            @media (min-width: ($s-mobile)) {
                margin-bottom: 25px;
            }

            &:before,
            &:after{
                display: none;
                content:'';
                position: absolute;
                z-index: 1;
                top: 0;
                height: 100%;
                width: 5%;

                @media (min-width: ($site-width)) {
                    display: block;
                }
            }
            &:before{
                left: 0;
                background: linear-gradient(to right, $bg-light, rgba($bg-light, 0%));
            }
            &:after{
                right: 0;
                background: linear-gradient(to left, $bg-light, rgba($bg-light, 0%));
            }

            .slide-container{
                transform: translateX(0px);
                animation: slide 30s linear infinite;
                display: flex;
                width: 100%;

                @keyframes slide {
                    from {
                        transform: translateX(0%);
                    }
                    to {
                        transform: translateX(-100%);
                    }
                }
            }

            .logos{
                display: flex;
                flex: 1 0 auto;
                min-width: 100%;
                justify-content: space-around;

                .logo{
                    display: block;
                }
            }
        }
    }

    section.intro{
        padding-top: 15px;
        padding-bottom: 0px;

        @media (min-width: ($s-mobile)) {
            padding-top: inherit;
            padding-bottom: inherit;
        }

        .intro-content{
            display: flex;
            flex-direction: column;

            @media (min-width: ($s-mobile)) {
                flex-direction: row;
            }
        }

        .infos{
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 40px 40px;

            @media (min-width: ($s-mobile)) {
                padding: 40px 50px;
            }
            @media (min-width: ($s-tablet)) {
                padding: 40px $site-width-pad-limited;
            }

            @media (min-width: ($s-mobile)) {
                flex: 1 0 50%;
            }

            @media (min-width: ($site-width)) {
                flex: 1 0 42%;
            }

            .title{
                margin-bottom: 0;
            }

            .link{
                margin-top: 20px;
            }
        }

        .text{
            display: flex;
            align-items: center;
            flex: 1 1 auto;
            padding: 40px $site-width-pad-x;
            margin-bottom: 0;

            @media (min-width: ($s-mobile)) {
                padding: 40px 80px;
            }
        }
    }

    section.showcase{
        padding-top: 0;
        padding-bottom: 20px;

        @media (min-width: ($s-mobile)) {
            padding-top: inherit;
            padding-bottom: 55px;
        }

        .picture{
            img{
                width: 100%;
            }
        }
    }

    section.solution{

        .title{
            margin-bottom: 60px;
        }

        .list{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 50px 0;

            @media (min-width: ($s-mobile)) {
                grid-template-columns: repeat(3, 1fr);
                gap: 50px 20px;
            }

            @media (min-width: ($s-tablet)) {
                gap: 50px 50px;
            }

            .block{
                display: flex;
                flex-direction: column;

                .picture{
                    height: 180px;
                    background-color: #ffffff;

                    img{
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                    }
                }

                .infos{
                    padding: 40px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @media (min-width: ($s-tablet)) {
                        padding: 50px;
                    }

                    .title{
                        margin-bottom: 10px;
                        @include multilineEllipsis(3);
                    }

                    .text{
                        @include multilineEllipsis(5);
                        margin-bottom: 0;
                    }
                }
            }
        }

        .link{
            margin-top: 31px;
            text-align: center;
        }
    }

    section.content-blocks{
        padding-top: 120px;

    }

    section.network{

        .network-content{
            display: flex;
            flex-direction: column;

            @media (min-width: ($s-mobile)) {
                flex-direction: row;
            }
        }

        .infos{
            flex: 1 1 auto;
            padding: 40px 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @media (min-width: ($s-mobile)) {
                padding: 50px 50px;
            }
            @media (min-width: ($s-tablet)) {
                padding: 50px $site-width-pad-limited;
            }

            .title{
                margin-bottom: 10px;
            }

            .text{
                margin-bottom: 0;
            }

            .link{
                margin-top: 20px;
            }
        }

        .picture{
            flex: 1 0 50%;
            position: relative;

            img{
                object-fit: cover;
                width: 100%;
                height: 100%;

                @media (min-width: ($s-mobile)) {
                    position: absolute;
                }
            }
        }
    }

    section.testimonies{
        padding-top: 10px;

        // see base.scss
    }

    section.team{
        padding-bottom: 10px;

        @media (min-width: ($s-mobile)) {
            padding-bottom: 52px;
        }

        .team-content{
            padding: 40px 0px;
            display: flex;
            flex-direction: column;

            @media (min-width: ($s-mobile)) {
                padding: 60px 0px;
                flex-direction: row;
            }

            @media (min-width: ($s-tablet)) {
                padding: 100px 0px;
            }
        }

        .infos{
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .title{
                margin-bottom: 10px;
            }

            .text{

            }

            .link{
                margin-top: 20px;
            }
        }

        .picture{
            flex: 1 0 40%;
            position: relative;
            order: -1;
            margin-bottom: 30px;

            @media (min-width: ($s-mobile)) {
                order: 0;
                margin-bottom: 0;
                margin-left: 60px;
            }

            @media (min-width: ($s-tablet)) {
                margin-left: 100px;
            }

            img{
                object-fit: contain;
                width: 100%;
                height: 100%;

                @media (min-width: ($s-mobile)) {
                    position: absolute;
                    object-fit: contain;
                }
                @media (min-width: ($s-tablet)) {
                    object-fit: cover;
                }
            }
        }
    }

    section.blog{
        padding-bottom: 30px;

        @media (min-width: ($s-mobile)) {
            padding-bottom: 55px;
        }

        .title{
            margin-bottom: 30px;

            @media (min-width: ($s-mobile)) {
                margin-bottom: 50px;
            }
        }

        .blogs-list{
            margin-bottom: 30px;

            @media (min-width: ($s-mobile)) {
                margin-bottom: 54px;
            }
        }

        .link{
            text-align: center;
        }

    }

    section.faq{
        .faq-content{
            padding: 40px 0px;

            @media (min-width: ($s-mobile)) {
                padding: 50px 0px;
            }
            @media (min-width: ($s-tablet)) {
                padding: 50px 0px;
            }

            .columns{
                display: flex;
                flex-direction: column;

                @media (min-width: ($s-mobile)) {
                    flex-direction: row;
                }
            }

            .infos{
                flex: 1 1 50%;
                margin-bottom: 30px;

                @media (min-width: ($s-mobile)) {
                    padding-right: 40px;
                    margin-bottom: 0;
                }

                @media (min-width: ($s-tablet)) {
                    padding-right: 94px;
                }

                .sticky{
                    position: sticky;
                    padding-bottom: 0;
                    top: $header-height-min + 30px;

                    @media (min-width: $s-nav-burger-limit) {
                        top: $header-height-max + 30px;
                    }
                }

                .title{
                    margin-bottom: 16px;
                }

                .text{
                    margin-bottom: 0;
                }

                .link{
                    margin-top: 28px;
                }
            }

            .list{
                flex: 1 1 50%;
            }
        }
    }

    section.cta{
        padding-bottom: 30px;

        @media (min-width: ($s-tablet)) {
            padding-bottom: 80px;
        }

        .cta-content{
            display: flex;
            flex-direction: column;

            @media (min-width: ($s-tablet)) {
                flex-direction: row;
            }

            .infos{
                flex: 1 0 37%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                margin-bottom: 30px;

                @media (min-width: ($s-mobile)) {
                    padding-right: 50px;
                    margin-bottom: 0;
                }
            }

            .list{
                $gap: 30px;

                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: $gap;

                @media (min-width: ($s-mobile)) {
                    //grid-template-columns: repeat(2, 1fr);
                    grid-template-columns: repeat(auto-fit, minmax(calc(50% - $gap / 2), 1fr));
                }

                .block{
                    width: 100%;
                    padding: 40px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .title{
                        margin-bottom: 10px;
                    }

                    .text{
                        font-size: 14px;
                        margin-bottom: 0;
                    }

                    .link{
                        margin-top: 28px;
                        text-align: center;

                        a{
                            width: 100%;
                        }
                    }

                }
            }
        }

    }
}